import { DependencyList, useEffect, useState } from "react";

export function useLocalObject<T extends Object | any[]>(
  key: string,
  defaultValue?: T,
  deps?: DependencyList
): [T, (value?: T) => void] {
  const [state, set] = useState<T>(defaultValue ?? null);

  useEffect(() => {
    const currentState = localStorage[key];
    if (currentState) {
      const value = JSON.parse(currentState) as T;
      set(value);
    }
  }, deps ?? []);

  const setState = (value?: Partial<T>) => {
    const isArray = Array.isArray(value);
    const finalValue = isArray ? (value as T) : ({ ...state, ...value } as T); //add new keys to objects

    if (value !== undefined) {
      localStorage[key] = JSON.stringify(finalValue);
      set(finalValue);
    } else {
      localStorage.removeItem(key);
      set(undefined);
    }
  };

  return [state, setState];
}

function useLocalState(key: string, deps?: DependencyList): [string | undefined, (value?: string) => void] {
  const [state, set] = useState<string>();

  useEffect(() => {
    const currentState = localStorage[key];
    if (state !== currentState && currentState) {
      set(currentState);
    }
  }, deps ?? []);

  const setState = (value?: string) => {
    if (value !== undefined) {
      localStorage[key] = value;
      set(value);
    } else {
      localStorage.removeItem(key);
      set(undefined);
    }
  };

  return [state, setState];
}
export default useLocalState;
