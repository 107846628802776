import { useEffect } from "react";

interface ObserverProps {
  hasLoaded: boolean;
}

const useObservers = (props: ObserverProps) => {
  useEffect(() => {
    if (!props.hasLoaded) return;
    //Observe texts
    let observer;
    setTimeout(() => {
      observer = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.remove("hide-for-observer");
              entry.target.classList.remove("prevent-card-flip");
              observer.unobserve(entry.target);
            }
          });
        },
        { threshold: 0.45 }
      );

      const textsToAnimate = document.querySelectorAll(".hide-for-observer");
      const flipCards = document.querySelectorAll(".prevent-card-flip");
      textsToAnimate.forEach((t) => observer.observe(t));
      flipCards.forEach((t) => observer.observe(t));
    }, 1000);

    return () => observer.disconnect();
  }, [props.hasLoaded]);
};

export default useObservers;
