import { useEffect, useState } from 'react';
import { on, off } from '../../assets/js/utils/functions';

const getValue = (search: string, params: string[]) => {
  const results = {};

  params.forEach(param => {
    results[param] = new URLSearchParams(search).get(param);
  })

  return results;
};

export type UseQueryParam = (params: string[]) => {[key: string]: string} | null;

const useSearchParams: UseQueryParam = (params) => {
  const [value, setValue] = useState<{[key: string]: string} | {}>({});

  useEffect(() => {
    if(window){
      setValue(() => getValue(location.search, params));

      const onChange = () => {
        setValue(getValue(location.search, params));
      };
  
      //trigger custom locationchange event
      window.history.pushState = ( f => function pushState(){
        const ret = f.apply(this, arguments);
        window.dispatchEvent(new Event('locationchange'));
        return ret;
      })(history.pushState);
    
      window.history.replaceState = ( f => function replaceState(){
        const ret = f.apply(this, arguments);
        window.dispatchEvent(new Event('locationchange'));
        return ret;
      })(history.replaceState);


      on(window, 'popstate', onChange);
      on(window, 'locationchange', onChange);

      return () => {
        off(window, 'locationchange', onChange);
        off(window, 'popstate', onChange);
      };
    }
  }, []);

  return value;
};

export default useSearchParams;