import React, { useState } from "react";
import { getStyles } from "../../../assets/js/utils/functions";
import { FrontPagesNavigation } from "../commons";
import classNames from "classnames";
import ContentScroller from "../commons/content-scroller";

const ChowbotComingSoonHeader = () => {
  return (
    <header className="front-header front-header--about-us front-header--chowbot pb-17.5 sm:pb-22.5 md:pb-25 lg:pb-30 relative">
      <div
        className="!absolute h-full w-full top-0 left-0 z-[0]"
        style={{
          background: "url(/images/patterns/green-stripe.svg)",
          backgroundPosition: "cover",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
        }}
      />
      <div className="relative z-[100]">
        <nav className="body-padding pt-10 sm:pt-12.5 md:pt-15 flex items-center justify-center relative !z-[99]">
          <a className="inline-block z-[999]">
            <img
              src="/images/logos/chowbot-logo-round.svg"
              alt="Chowbot Logo"
              className="h-15 sm:h-[70px] lg:h-[75px]"
            />
          </a>
        </nav>
        <div className="body-padding">
          <div className="mt-7.5 sm:9 md:mt-10 flex flex-col items-center container">
            <div className="flex flex-col items-center max-w-full">
              <h1 className={classNames("font-bold text-center text-black mt-5 heading-two")}>
                <div className="words-line !overflow-visible">
                  <span className="anim-word-up inline-block">Hungry</span>{" "}
                  <span className="anim-word-up inline-block" style={getStyles({ "--delay": "0.15s" })}>
                    stomachs
                  </span>{" "}
                </div>{" "}
                <div className="words-line font-bold text-accent-green-500">
                  <span
                    className="anim-word-up inline-block !leading-tight"
                    style={getStyles({ "--delay": "0.45s", transform: "scale(1.25)" })}
                  >
                    Don&apos;t Wait!!!
                  </span>
                </div>{" "}
              </h1>
              <p
                className={classNames(
                  "text-black-secondary text-center follow-text font-normal max-w-[375px] sm:max-w-[540px] lg:max-w-[745px] xl:max-w-[780px] mt-3.75 sm:mt-5 slide-text-in hero-text"
                )}
                style={getStyles({ "--delay": "0.6s" })}
              >
                Let chowbot handle orders and payment collection from your customers via WhatsApp while you focus on
                making great food!
              </p>
            </div>
            <div
              className="mt-8 sm:mt-10 md:mt-12.5 lg:mt-15 w-full slide-text-in"
              style={getStyles({ "--delay": "0.75s" })}
            >
              <div className="w-full max-w-[1150px] mx-auto header-video">
                <figure className="w-full overflow-hidden relative safari-overflow-fix flex items-center justify-center">
                  <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                    <div className="grid gap-4">
                      <div>
                        <img
                          className="h-auto max-w-full rounded-xl"
                          src="https://res.cloudinary.com/catlog/image/upload/e_improve,w_500/v1713912776/mansory-grid/5.jpg"
                          alt=""
                        />
                      </div>
                      <div>
                        <img
                          className="h-auto max-w-full rounded-xl"
                          src="https://res.cloudinary.com/catlog/image/upload/e_improve,w_500,h_180,c_thumb,g_auto/v1713913825/mansory-grid/2148803863.jpg"
                          alt=""
                        />
                      </div>
                      <div>
                        <img
                          className="h-auto max-w-full rounded-xl"
                          src="https://res.cloudinary.com/catlog/image/upload/e_improve,w_500/v1713914799/mansory-grid/diptych01-480_x2.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="grid gap-4">
                      <div>
                        <img
                          className="h-auto max-w-full rounded-xl"
                          src="https://res.cloudinary.com/catlog/image/upload/e_improve,w_500/v1713915090/mansory-grid/medium-shot-barista-making-coffee.jpg"
                          alt=""
                        />
                      </div>
                      <div>
                        <img
                          className="h-auto max-w-full rounded-xl"
                          src="https://res.cloudinary.com/catlog/image/upload/e_improve,w_500,h_700,c_thumb,g_auto/v1713912779/mansory-grid/2.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="hidden md:grid gap-4">
                      <div>
                        <img
                          className="h-auto max-w-full rounded-xl"
                          src="https://res.cloudinary.com/catlog/image/upload/e_improve,w_500/v1713912781/mansory-grid/1.jpg"
                          alt=""
                        />
                      </div>
                      <div>
                        <img
                          className="h-auto max-w-full rounded-xl"
                          src="https://res.cloudinary.com/catlog/image/upload/c_scale,w_500/v1713914403/mansory-grid/5566.jpg"
                          alt=""
                        />
                      </div>
                      <div>
                        <img
                          className="h-auto max-w-full rounded-xl"
                          src="https://res.cloudinary.com/catlog/image/upload/c_scale,w_500/v1713913825/mansory-grid/35451.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="hidden md:grid gap-4">
                      <div>
                        <img
                          className="h-auto max-w-full rounded-xl"
                          src="https://res.cloudinary.com/catlog/image/upload/c_scale,w_500/v1713912776/mansory-grid/5487.jpg"
                          alt=""
                        />
                      </div>
                      <div>
                        <img
                          className="h-auto max-w-full rounded-xl"
                          src="https://res.cloudinary.com/catlog/image/upload/c_scale,w_500/v1713912783/mansory-grid/7.jpg"
                          alt=""
                        />
                      </div>
                      <div>
                        <img
                          className="h-auto max-w-full rounded-xl"
                          src="https://res.cloudinary.com/catlog/image/upload/e_improve,w_500/v1713912780/mansory-grid/3.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="absolute starburst-container">
                    <div className="starburst text-white font-display text-xl sm:text-2xl lg:text-[28px] text-center font-bold h-20 w-20 sm:h-25 sm:w-25 lg:h-30 lg:w-30 bg-accent-green-500 !leading-none">
                      <span>
                        Coming
                        <br />
                        Soon
                      </span>
                    </div>
                  </div>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

const foodEmojis = [
  {
    emoji: "🍔",
    start: "50%",
    speed: "15s",
    delay: "1s",
  },
  {
    emoji: "🍳",
    start: "125%",
    speed: "17.5s",
    delay: "0.5s",
  },
  {
    emoji: "🍜",
    start: "-250%",
    speed: "18s",
    delay: "2.5s",
  },
  {
    emoji: "🥤",
    start: "200%",
    speed: "15s",
    delay: "0.25s",
  },
  {
    emoji: "🍕",
    start: "-150%",
    speed: "22s",
    delay: "5s",
  },
  {
    emoji: "🍹",
    start: "225%",
    speed: "20s",
    delay: "3s",
  },
  {
    emoji: "🍝",
    start: "-100%",
    speed: "19s",
    delay: "6s",
  },
];

export default ChowbotComingSoonHeader;
