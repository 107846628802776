import React, { useEffect } from "react";
import { FrontPagesCTA, FrontPagesFooter } from "../../components/front-pages/commons";
import AnimatedHeaderText from "../../components/front-pages/commons/animated-header-text";
import {
  FindWithCatlogCustomerFeatures,
  FindWithCatlogExtras,
  FindWithCatlogHeader,
  FindWithCatlogHowItWorks,
  FindWithCatlogSellerFeatures,
} from "../../components/front-pages/findwithcatlog";
import FrontPagesLayout from "../../components/front-pages/front-pages-layout";
import { PageTags } from "../../components/seo-tags";
import PagesLoaderContext from "../../contexts/page-loader-context";
import ChowbotComingSoonHeader from "@/components/front-pages/chowbot/coming-soon-header";
import Head from "next/head";

const pageTags: PageTags = {
  title: "Chowbot - Make more money from your food business on Whatsapp",
  description:
    "Chowbot handles order-taking and payment collection from your customers via WhatsApp chat while you focus on making amazing food!",
  pageUrl: "/chowbot",
  image: "https://res.cloudinary.com/catlog/image/upload/v1713894172/seo-banners/chowbot-banner.png",
};

const StoreLinksPage = () => {
  useEffect(() => {
    const favIcon = document.querySelector("link[rel=icon]");
    favIcon["href"] = "/chowbot-fav.png";
  }, []);
  return (
    <>
      <PagesLoaderContext.Provider>
        <FrontPagesLayout
          colors={{
            main: "#39B588",
            pastel: "#EBFFF7",
          }}
          tags={pageTags}
          hideAnimation
        >
          <main>
            <ChowbotComingSoonHeader />
          </main>
        </FrontPagesLayout>
      </PagesLoaderContext.Provider>
    </>
  );
};

export async function getStaticProps(context) {
  return {
    props: {}, // will be passed to the page component as props
  };
}

export default StoreLinksPage;
